import React, { useEffect } from "react";

import ExperienceCards from "components/thank-you/ExperienceCards";

import GlobalLayout from "components/page/GlobalLayout";
import { Helmet } from "react-helmet";
import debug from "debug";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";

const log = debug("ThankYou");

export default function ThankYouPageReferral() {
  useEffect(() => {
    if (window && window.ga) {
      log("google conversion lead was tracked");
      window.ga("event", "conversion", {
        send_to: "AW-970586936/DNEjCJ7f15UCELj2584D",
      });
    }
  }, []);

  return (
    <GlobalLayout>
      <Helmet>
        <title>Thank you for registering!</title>
        <meta
          name="description"
          content="Thank you for Registering! Please keep an eye out for your confirmation email."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SectionContainer>
        <SectionInner style={{ flexDirection: "column", gap: "5rem" }}>
          <SectionCol style={{ width: "100%" }}>
            <TextContainer>
              <FancyPageTitle>Thank you for registering!</FancyPageTitle>
              <MainParagraph>
                Please keep an eye out for your confirmation email.
              </MainParagraph>
            </TextContainer>
          </SectionCol>
          <ExperienceCards />
        </SectionInner>
      </SectionContainer>
    </GlobalLayout>
  );
}
